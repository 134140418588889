import React, { Component } from "react";
import { Layout } from "../components";
import AudytForm from "../components/AudytForm";
import AudytTable from "../components/AudytTable";
import locales from "../constants";
import $ from "jquery";

class Audyt extends Component {
  images = {
    audytGraph: require("../assets/img/audyt/na-czym-polega.png"),
    pelnyAudyt: require("../assets/img/audyt/pelny-audyt.png"),
    testUx: require("../assets/img/audyt/test-ux.png"),
    analizaKon: require("../assets/img/audyt/analiza.png"),
    analizaRuch: require("../assets/img/audyt/analiza-ruchu.png"),
    kosmos: require("../assets/img/audyt/kosmos-ux.svg"),
  };

  componentDidMount() {
    setTimeout(function () {
      $(".line").addClass("show");
    }, 750);
  }

  render() {
    const { slug } = this.props.pageContext;
    const lang = "pl";
    const { audytGraph, pelnyAudyt, testUx, analizaKon, analizaRuch, kosmos } =
      this.images;

    return (
      <Layout
        seo={{
          title: "Audyt UX",
          href: slug,
          lang: "pl",
        }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/ux-audit/",
        }}
        header={{ icons: "#eab941" }}
      >
        <section className="container-fluid audytHero">
          <h1>Audyt UX</h1>
          <h2>
            Nie licz na fux
            <span className="line line_1"></span>
            <span className="line line_2"></span>
          </h2>
        </section>
        <section className="container-fluid audytTwoCol">
          <div className="row justify-content-center">
            <div className="col-md-5 col-lg-4 audytTwoCol__col">
              <h3 className="audytHeading">Wymagania rosną</h3>
              <p>Rośnie poziom świadomości i oczekiwań użytkowników.</p>
              <p>
                Jak podołać tempu zmian?
                <br />
                Jak odnaleźć się w gąszczu informacji?
                <br />
                Co gdybyśmy wiedzieli czego chcą nasi klienci/użytkownicy?
              </p>
              <p>
                {" "}
                Z pomocą przychodzi nam User Experience Design, badający
                użytkowników i ich zachowania. To dziedzina która zdobywa coraz
                więcej popularności, na całym świecie. Bo skoro mamy już coś
                robić, to najlepiej róbmy to z głową.{" "}
              </p>
            </div>
            <div className="col-md-5 col-lg-4 audytTwoCol__col">
              <h3 className="audytHeading">Co to jest audyt UX</h3>
              <p>
                To ocena pod względem użyteczności, wygody korzystania oraz
                diagnoza kluczowych błędów utrudniających lub uniemożliwiających
                korzystanie ze strony.{" "}
              </p>
              <p>
                Audyt przeprowadza się, aby sprawdzić jak działa strona i czy
                spełnia swoje zadanie.
              </p>
              <p>
                Pozwala zlokalizować kluczowe błędy, utrudniające lub
                uniemożliwiające użytkownikowi realizację celu strony (np.
                zrobienie zakupu, wypełnienie formularza kontaktowego itp).
                Poprawiając je, zwiększamy konwersję.
              </p>
            </div>
          </div>
        </section>
        <section className="container-fluid audytGraph">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h3 className="audytHeading">Na czym polega audyt UX?</h3>
              <div className="audytGraph__container">
                <img src={audytGraph} alt="" className="audytGraph__image" />
                <img
                  src={kosmos}
                  alt=""
                  className="audytGraph__image--mobile"
                />
                <p className="audytGraph__p1">
                  Wykryj błędy na swojej stronie. Miejsca, które utrudniają
                  zrealizowanie zakupów lub uniemożliwiają swobodne znalezienie
                  informacji.{" "}
                </p>
                <p className="audytGraph__p2">
                  Dowiedz się, które elementy należy wyeksponować.
                </p>
                <p className="audytGraph__p3">
                  Dowiedz się, które teksty wymagają modyfikacji lub większej
                  ekspozycji. Czy są one zrozumiałe i pasujące do odbiorców?
                </p>
                <p className="audytGraph__p4">
                  Poprzez audyt usprawnisz procesy na stronie. Dostarczysz
                  odpowiednie informacje w odpowiednim dla klienta momencie.
                </p>
                <p className="audytGraph__p5">
                  Poprzez likwidację błędów zwiększasz wiarygodność i rzetelność
                  w internecie.
                </p>
                <p className="audytGraph__p6">
                  Popraw komunikację z klientem, poprzez dostosowanie materiałów
                  do użytkownika. Zrozumiesz jego motywacje i działania na
                  stronie.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid audytMatch">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h3 className="audytHeading">Dopasuj do swoich potrzeb</h3>
              <p className="audytMatch__description">
                Aby audyt miał sens i dał realne korzyści, powinien być
                dopasowany do Twoich potrzeb.
                <br />
                Zacznij od określenia celu audytu lub problemu na stronie, to
                pozwoli na wybór odpowiedniego zakresu i formy audytu.
              </p>
              <div className="audytMatch__top">
                <div className="audytMatch__leftBox">
                  <p>Pełny audyt UX</p>
                </div>
                <div className="audytMatch__col">
                  <img src={pelnyAudyt} alt="" />
                </div>
                <div className="audytMatch__col">
                  <ul>
                    <li>
                      Konsultacje oraz warsztat, pozwalające wspólnie dobrać
                      odpowiednie
                    </li>
                    <li>
                      Podstawowe analizy działania strony (desk research,
                      wędrówka poznawcza i test ux) parametry audytu
                    </li>
                    <li>Analiza konkurencji</li>
                    <li>Analiza ruchu na stronie, w wybranych obszarach</li>
                  </ul>
                </div>
                <div className="audytMatch__col">
                  <ul>
                    <li>
                      Analiza kontentu strony i jego dopasowania do odbiorców
                      strony
                    </li>
                    <li>Rekomendacje, również w formie wizualnej</li>
                    <li>Raport z prezentacją</li>
                    <li>
                      Konsultacja wyniku audytu, po zapoznaniu się z raportem i
                      rekomendacjami
                    </li>
                  </ul>
                </div>
              </div>
              <div className="audytMatch__bottom">
                <div className="audytMatch__leftBox">
                  <p>Audyty częsciowe</p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={testUx} alt="" />
                  </div>
                  <h4>Test UX</h4>
                  <p>
                    Taką wersję audytu możemy wykonać np. gdy planujemy zmiany
                    na stronie lub gdy zaobserwowano trudności z poruszaniem się
                    po stronie.{" "}
                  </p>
                  <p>
                    W efekcie takiego audytu, dowiemy się np. jakie są
                    najistotniejsze błędy na stronie, na których{" "}
                  </p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={analizaKon} alt="" />
                  </div>
                  <h4>Analiza konkurencji</h4>
                  <p>
                    Taką wersję audytu możemy wykonać np. gdy chcemy się
                    dowiedzieć jak wygląda nasza strona w porównaniu z
                    konkurencją.
                  </p>
                  <p>
                    W efekcie takiego audytu, dowiemy się np. jakie rozwiązania
                    się sprawdzają (w tym biznesie), jak wypadamy na tle
                    konkurencji (pod względem funkcjonalnym i wizualnym).
                  </p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={analizaRuch} alt="" />
                  </div>
                  <h4>
                    Analiza ruchu
                    <br />
                    na stronie
                  </h4>
                  <p>
                    Taką wersję audytu możemy wykonać np. gdy zauważymy, że
                    użytkownicy wypełniają jedynie część formularza lub
                    przerywają proces rejestracji czy zakupu.
                  </p>
                  <p>
                    W efekcie takiego audytu, dowiemy się np. które elementy na
                    stronie ją dla użytkowników problematyczne, z których
                    korzystają najczęściej, a z których wcale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid audytTable">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <AudytTable lang={lang} />
            </div>
          </div>
        </section>
        <section className="container-fluid audytContact">
          <div className="row justify-content-center">
            <div className="col-md-4 col-lg-3">
              <h3 className="audytHeading">
                Jaki zakres audytu powinieneś wybrać?
              </h3>
              <p>
                Napisz do nas, Nasi eksperci chętnie pomogą Ci dobrać odpowiedni
                zakres i formę audytu.
              </p>
            </div>
            <div className="col-md-6 col-lg-5">
              <AudytForm locales={locales[lang]} lang={lang} />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Audyt;
